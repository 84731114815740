/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.7.1/dist/jquery.min.js
 * - /npm/locomotive-scroll@4.1.4/dist/locomotive-scroll.min.js
 * - /npm/@barba/core@2.10.3/dist/barba.umd.min.js
 * - /npm/@barba/prefetch@2.2.0/dist/barba-prefetch.umd.min.js
 * - /npm/swiper@11.1.14/swiper-bundle.min.js
 * - /npm/gsap@3.12.5/dist/ScrollTrigger.min.js
 * - /npm/gsap@3.12.5/dist/gsap.min.js
 * - /npm/animejs@3.2.2/lib/anime.min.js
 * - /npm/@lottiefiles/lottie-player@2.0.12/dist/lottie-player.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
